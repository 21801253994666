import { Accordion, Badge, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import userApi from "../../app/services/secured/user";
import { StorageManager } from "../../app/services/storage";
import logo from '../../assets/img/logo-white.svg';
import Urls from "../../assets/js/Urls";
import { MenuAccordionProps, MenuItemProps } from "../../types";
import { ButtonIcon, Icon } from "../misc/Buttons";
import Menu, { getActiveKey } from './SidebarMenu';

const Sidebar: React.FC = () => {
  const user = userApi.useProfileQuery();
  const [ logout, logoutRes ] = userApi.useLogoutMutation();
  return <>
    <nav id='app-navigation'>
      <div className='d-flex flex-column justify-content-between h-100'>
        <Nav.Link href={Urls.dash}>
          <div className='w-100 d-flex justify-content-center'>
            <img id='app-logo' src={logo} alt='genelead-logo'/>
          </div>
        </Nav.Link>

        <div className='d-flex flex-column mb-auto' style={{ minHeight: '60vh' }}>
          <Accordion defaultActiveKey={getActiveKey(Menu)}>
            {Menu.filter(m => m.title === 'Admin' && !user.data?.isUserAdmin ? false : true).map(( m, key ) => {
                return <MenuAccordionItem key={Math.random()} title={m.title} icon={m.icon} eventKey={key.toString()}>
                  {m.items.map(i =>
                    <MenuItem key={Math.random()} icon={i.icon} link={i.link}>{i.name}</MenuItem>)}
                </MenuAccordionItem>
              }
            )}
          </Accordion>
        </div>

        <div>
          <div className='d-flex justify-content-center mb-3'>
            <a 
              href={`https://admin-3cduanzibq-od.a.run.app/?access_token=${StorageManager.get()}`}
              target="_blank"
              rel="noopener noreferrer"
              className='btn btn-new-app fs-12'
            >
              Version 2.0 <Badge bg="secondary">Bêta</Badge>
            </a>
          </div>
          <div className='d-flex justify-content-center'>
            <ButtonIcon
              className='fs-16 text-light'
              code={"box-arrow-left"}
              size={"sm"}
              variant={""}
              onClick={logout}
            >
              Déconnexion
            </ButtonIcon>
          </div>
        </div>
      </div>
    </nav>
  </>
}

const MenuItem: React.FC<MenuItemProps> = props => {
  return <div className='menu-item'>
    <NavLink to={props.link}>
      <Icon code={props.icon}/>
      <div>{props.children}</div>
    </NavLink>
  </div>
}

const MenuAccordionItem: React.FC<MenuAccordionProps> = props => {
  return <>
    <Accordion.Item eventKey={props.eventKey}>
      <Accordion.Header>
        <Icon code={props.icon}/>
        <div>{props.title}</div>
      </Accordion.Header>
      <Accordion.Body>
        {props.children}
      </Accordion.Body>
    </Accordion.Item>
  </>
}

export default Sidebar;